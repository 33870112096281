import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import GiftCardBackground from "../components/giftcardbackground";
import settings from "../../settings";

// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/specialoffers/sale-banner.jpg`;
const amexBanner = `${settings.IMAGES_BASE_URL}/images/specialoffers/amex.jpg`;

const SpecialOffersAMEX = () => {
  return (
    <Layout>
      <SEO
        title="BMW Performance Driving School | Gift Cards"
        description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!"
      />
      <div className="giftcardpage giftcardpageretargeting">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />

        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h2>Special Offers</h2>
            </div>
          </div>
          <FlexCard
            rowType="row"
            image={amexBanner}
            imageAlt="THE OFFER OF CHAMPIONS"
            header="THE OFFER OF CHAMPIONS"
            subheader="GET 20% OFF MOST CLASSES AND EXPERIENCES"
            btnText="Call 888-345-4269 to book"
            btnStyle="btn-blue-offers"
            phone="tel:888-345-4269"
          >
            <p>
              We’ve teamed up with AMEX for a special offer that’s sure to get your adrenaline pumping. Get 20% off most classes and Experiences at our Thermal location. Hurry, this offer goes as fast as our BMWs.
              <br/><br/>
              Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class today. Use promo code <span className="bold">25AMEXGOLFT20</span>. Must purchase gift card between 1/13/2025 and 1/20/2025. M4 GT4 Experience, Race License School and Private Instruction excluded. Valid in California location only.
            </p>
          </FlexCard>
        </section>
      </div>
    </Layout>
  );
};

export default SpecialOffersAMEX;
